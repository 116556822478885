// @ts-nocheck
import React from "react"
import { Box } from "@chakra-ui/react"
import { Link } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import { mixPanel } from "../../mixpanel/mixpanel"

const Ads = () => {
  const handleTrackClick = value => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", value)
      }
    }
    mixPanel.track(value)
  }

  return (
    <>
      <Box
        mx={{ "2xl": "16rem", xl: "6rem", lg: "2rem", base: "0.5rem" }}
        my={"32"}
        textAlign={"center"}
      >
        <Box
          w="100%"
          bgGradient="linear(to-l, #A907FB, #0C6CC5)"
          textAlign={"center"}
          rounded={"lg"}
          pb="12"
        >
          <Box
            pt="16"
            mb="10"
            fontWeight={600}
            fontSize={{ xl: 25, base: 20 }}
            color="white"
            mx={{ xl: "32", lg: "16", base: "8" }}
          >
            <Trans>
              An efficient online school management software can get the job
              done with perfection and that too fully online.
            </Trans>
          </Box>
          <Link to="/GetADemo" onClick={() => handleTrackClick("Get Demo Tab")}>
            <Box
              as="button"
              color="#8326EA"
              bgColor={"white"}
              rounded={"full"}
              py={{ xl: "2", base: "1" }}
              px={{ xl: "10", base: "6" }}
              mx="auto"
              fontWeight={600}
              fontSize={{ xl: 20, base: 17 }}
            >
              <Trans>Get demo</Trans>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  )
}

export default Ads
