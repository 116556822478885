import React from "react"
import Ads from "../Home/Ads"
import Footer from "../Home/Footer"
import HomeNavBar from "../Home/HomeNavBar"
import Testimons from "./Testimons"

const index = () => {
  return (
    <>
      <HomeNavBar />
      <Testimons />
      <Ads />
      <Footer />
    </>
  )
}
export default index
